<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li *ngIf="category">
                    <a [routerLink]="['../']">{{ category || "" }}</a>
                </li>
                <li>{{ programYearAndTitle || "" }} Alumni</li>
            </ul>
            <!--            <h2>About Us</h2>-->
        </div>
    </div>
    <!--    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>-->
</div>

<div class="about-area-three pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div
                [ngClass]="
                    isCertificateAlumni ? 'col-10' : 'col-lg-5 col-md-12'
                "
            >
                <div class="about-content-box">
                    <h2>{{ programYearAndTitle }}</h2>
                    <p
                        [ngStyle]="{
                            'font-size': isCertificateAlumni
                                ? '1.3rem'
                                : '1.5rem'
                        }"
                    >
                        {{ programDescription }}
                    </p>
                </div>
            </div>
            <div
                [ngClass]="
                    isCertificateAlumni ? 'd-none' : 'col-lg-7 col-md-12'
                "
            >
                <div class="about-img">
                    <div class="image tw-flex tw-justify-center tw-items-center">
                        <ng-container *ngIf="groupPicture">
                            <img (load)="groupPictureLoaded = true" alt="image" height="420"
                                 width="670"
                                 priority
                                 class="tw-cursor-pointer"
                                 (click)="open(groupPicture, groupPictureCaption)"
                                 [ngSrc]="groupPicture" />
                        </ng-container>
                        <ng-container *ngIf="!groupPictureLoaded">

                            <img alt="image" height="200" priority
                                 width="200"
                                 class=" tw-w-[100px] tw-h-[100px] tw-bottom-[unset] tw-left-[unset] tw-bg-white"

                                 [ngSrc]="'assets/img/loading.gif'" />
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--<div class="shape3"><img src="assets/img/shape3.png" alt="image" /></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image" /></div>-->
</div>

<div class="advisor-area pt-70 pb-70 bg-f9f9f9">
    <ng-container *ngIf="isAlumniProfiles">
        <div class="container">
            <div class="section-title d-flex justify-content-between">
                <h2>Alumni Profiles</h2>
                <div class="form-group tw-w-[300px]  tw-border-2 tw-rounded-[8px] tw-bg-white">
                    <input
                        [(ngModel)]="searchTerm"
                        (input)="searchAlumni($event)"
                        class="form-control tw-border-2 tw-bg-white"
                        type="text"
                        placeholder="Search Alumni"
                    />
                </div>
            </div>
            <div
                class="row row-cols-1 row-cols-lg-4 row-cols-md-4 row-cols-sm-12 g-2"
            >
                <ng-container *ngIf="alumniProfiles | async as otherAlumni">
                    <ng-container
                        *ngFor="
                            let alumnus of otherAlumni
                                | searchFilter: searchTerm
                        "
                    >
                        <div class="col mb-lg-4 mb-sm-2">
                            <div class="single-advisor-item card h-100">
                                <div class="advisor-image">
                                    <!--TODO: remove background blur-->
                                    <!--<ng-container *ngIf="programYear !== '2023'">
                                        <div
                                            class="blur-bg"
                                            [ngStyle]="{
                                            'background-image':
                                                'url(\'' +
                                                alumnus?.picturePath +
                                                '\')'
                                        }"
                                        ></div>
                                    </ng-container>
-->
                                    <figure>
                                        <img
                                            appImgFallback="{{ fallBackImage }}"
                                            (click)="openProfiles(alumnus.id)"
                                            alt="{{alumnus?.Name}}"
                                            class="img-responsive profile-image tw-m-auto tw-object-contain"
                                            loading="lazy"
                                            [defaultImage]="'assets/img/Queens-Logo.jpg'"
                                            [lazyLoad]="alumnus?.picturePath"
                                        />
                                    </figure>
                                </div>
                                <div class="advisor-content card-body">
                                    <h5>{{ alumnus?.Name }}</h5>
                                    <span>{{ alumnus?.JobTitle }}</span>
                                    <span *ngIf="alumnus?.Organization">{{
                                            alumnus.Organization
                                        }}</span>
                                </div>
                                <div class="card-footer">
                                    <span *ngIf="alumnus?.Country">{{
                                            alumnus.Country
                                        }}</span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <ng-container *ngIf="tot">
                <div class="section-title d-flex justify-content-between mt-5">
                    <h2>Training of Trainers</h2>
                </div>
                <div
                    class="row row-cols-1 row-cols-lg-4 row-cols-md-4 row-cols-sm-12 g-2"
                >
                    <ng-container *ngFor="let alumnus of tot">
                        <div
                            class="col mb-lg-4 mb-sm-2"
                            [class.hideProfile]="!alumnus.visible"
                        >
                            <div class="single-advisor-item card h-100">
                                <div class="advisor-image">
                                    <!--  <ng-container *ngIf="+programYear < 2023">
                                          <div
                                              class="blur-bg"
                                              [ngStyle]="{
                                              'background-image':
                                                  'url(\'' +
                                                  alumnus?.picturePath +
                                                  '\')'
                                          }"
                                          ></div>
                                      </ng-container>-->
                                    <figure>
                                        <img
                                            [lazyLoad]="alumnus.picturePath"
                                            [defaultImage]="'assets/img/Queens-Logo.jpg'"
                                            alt="image"
                                            class="img-responsive profile-image tw-m-auto"
                                        />
                                    </figure>
                                </div>
                                <div class="advisor-content card-body">
                                    <h5>{{ alumnus?.Name }}</h5>
                                    <span>{{ alumnus?.JobTitle }}</span>
                                    <span *ngIf="alumnus?.Organization">{{
                                            alumnus.Organization
                                        }}</span>
                                </div>
                                <div class="card-footer">
                                    <span *ngIf="alumnus?.Country">{{
                                            alumnus.Country
                                        }}</span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="isCertificateAlumni">
        <div class="container">
            <div class="section-title d-flex justify-content-between">
                <h2>Alumni Profiles</h2>
                <div class="form-group">
                    <input
                        [(ngModel)]="searchTerm"
                        (input)="searchAlumni($event)"
                        class="form-control"
                        type="text"
                        placeholder="Search Alumni"
                    />
                </div>
            </div>
            <ng-container *ngIf="alumniProfiles | async as certificateAlumni">
                <ng-container *ngFor="let al of certificateAlumni">
                    <ng-container *ngIf="al.alumni.length > 0">
                        <div class="row">
                            <div class="col-12">
                                <div
                                    class="section-title d-flex justify-content-between mb-3"
                                >
                                    <h2>{{ al.year }}</h2>
                                </div>
                            </div>
                        </div>
                        <div
                            class="row row-cols-1 row-cols-md-5 row-cols-sm-12 g-2 mb-5"
                        >
                            <ng-container
                                *ngFor="
                                    let alumnus of al.alumni
                                        | searchFilter: searchTerm:'cert'
                                "
                            >
                                <div class="col mb-sm-2 mb-md-4">
                                    <div class="single-advisor-item card h-100">
                                        <div class="advisor-image">
                                            <!--    <div
                                                    class="blur-bg"
                                                    [ngStyle]="{
                                                        'background-image':
                                                            'url(\'' +
                                                            alumnus?.picturePath +
                                                            '\')'
                                                    }"
                                                ></div>-->
                                            <figure>
                                                <img

                                                    [defaultImage]="'assets/img/Queens-Logo.jpg'"
                                                    [lazyLoad]="alumnus?.picturePath"
                                                    alt="image"
                                                    class="img-responsive profile-image tw-m-auto tw-object-contain"
                                                    loading="lazy"
                                                />
                                            </figure>
                                        </div>
                                        <div class="advisor-content card-body">
                                            <h5>{{ alumnus?.FullName }}</h5>
                                            <span>{{ alumnus?.JobTitle }}</span>
                                            <span
                                                *ngIf="alumnus?.Organization"
                                            >{{
                                                    alumnus.Organization
                                                }}</span
                                            >
                                        </div>
                                        <div class="card-footer">
                                            <span *ngIf="alumnus?.Country">{{
                                                    alumnus.Country
                                                }}</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</div>

<!--<app-feedback></app-feedback>-->

<!--<app-instructor></app-instructor>-->

<!--<div class="premium-access-area bg-f9f9f9 ptb-100">-->
<!--    <app-premium-access></app-premium-access>-->
<!--    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>-->
<!--    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>-->
<!--    <div class="shape8"><img src="assets/img/shape7.png" alt="image"></div>-->
<!--</div>-->
