import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-about-style-four',
    templateUrl: './about-style-four.component.html',
    styleUrls: ['./about-style-four.component.scss'],
})
export class AboutStyleFourComponent implements OnInit {
    public faculty = [
        {
            name: 'Glenn Jenkins',
            title: 'Program Director',
            position: 1,
            imageUrl:
                'https://jdintl.econ.queensu.ca/uploads/about/Glenn-Resized-e1411149608614-150x150.jpg',
        },
        {
            name: 'Arnold Harberger',
            title: 'Distinguished Professor',
            position: 2,
            imageUrl:
                'https://jdintl.econ.queensu.ca/uploads/about/Harberger-copy-e1410983627548-150x150.png',
        },
        {
            name: 'George Kuo',
            title: 'Senior Fellow',
            position: 3,
            imageUrl:
                'https://jdintl.econ.queensu.ca/uploads/about/George-Kuo1-e1410983347914-150x150.jpg',
        },
        {
            name: 'Hatice Jenkins',
            title: 'Senior Fellow',
            titleTwo: 'Director FIPEG',
            position: 4,
            imageUrl:
                'https://jdintl.econ.queensu.ca/uploads/about/loKrFkGG-150x150.jpeg',
        },
        {
            name: 'Mikhail Miklyaev',
            title: 'Program Manager',
            titleTwo: 'Senior Lecturer',
            imageUrl:
                'https://jdintl.econ.queensu.ca/uploads/about/mikhail-lecturer-150x150.jpg',
        },
        {
            name: 'Hasan Altiok',
            title: 'Senior Lecturer',
            position: 5,
            imageUrl:
                'https://jdintl.econ.queensu.ca/uploads/about/Hasan-Altiok-picture-2015-08-28-enhanced-2891-150x150.jpeg',
        },
        {
            name: 'Savvakis Savvides',
            title: 'Senior Lecturer',
            position: 6,
            imageUrl:
                'https://jdintl.econ.queensu.ca/uploads/about/Savvakis-Savvides.jpg',
        },
        // {
        //     name: 'Hanna Zowall',
        //     title: 'Senior Fellow',
        //     position: 7,
        //     imageUrl:
        //         'https://jdintl.econ.queensu.ca/uploads/about/Hanna-Zowall.jpg',
        // },
        /*  {
              name: 'Zameer Qader',
              title: 'Assistant Manager',
              position: 8,
              imageUrl:
                  'https://jdintl.econ.queensu.ca/uploads/about/20160802-GBP-JDIC-202-e1486410646577-150x150.jpg',
          },*/
        {
            name: 'Fereshteh Pourmohammadi',
            title: 'Program Co-ordinator',
            position: 10,
            imageUrl: '../assets/img/staff/MELEK.jpg',
        },
    ];

    constructor() {}

    ngOnInit(): void {}
}
