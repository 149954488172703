import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlumniService } from '../../../../services/alumni/alumni.service';
import { Observable, of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { Lightbox } from 'ngx-lightbox';

const DOMAIN = 'https://api.jdintl.cri-world.com/api/';

@Component({
    selector: 'app-alumni-profiles',
    templateUrl: './alumni-profiles.component.html',
    styleUrls: ['./alumni-profiles.component.scss'],
})
export class AlumniProfilesComponent implements OnInit {
    public alumniProfiles: Observable<any>;
    programYearAndTitle: string;
    isAlumniProfiles = false;
    isCertificateAlumni = false;
    programDescription: string;
    picturePath: string;
    groupPicture: string;
    groupPictureCaption = '';
    category: string;
    searchTerm: string;
    tot: any;
    fallBackImage = 'assets/img/Queens-Logo.jpg';

    groupPictureLoaded: boolean;
    profilePicturesGallery = [];
    programYear: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private alumniService: AlumniService,
        private sanitizer: DomSanitizer,
        public lightbox: Lightbox
    ) {}

    open(src: string, caption): void {
        const album = {
            src,
            caption,
            thumb: src,
        };
        // open lightbox
        this.lightbox.open([album], 0);
    }

    openProfiles(index: number): void {
        // open lightbox
        this.lightbox.open(this.profilePicturesGallery, index);
    }

    private groupBy(objectArray, property): any {
        return objectArray.reduce((acc, obj) => {
            const key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    //  Arrey Gilbert

    goBack(): void {
        // this.router.go
    }

    searchAlumni(searchEvent): void {
        const term = searchEvent.target?.value;
        console.log(term);
    }

    // TODO: update widht and height of groupPicture to 670 x 420
    ngOnInit(): void {
        /*this.alumniService.getJsonAlumni().subscribe((data) => {
            console.log(data);
            for (const k in data) {
                if (data[k]) {
                    // console.log(data[k]);
                    /!*  this.alumniService.updateAlumniData(
                          data[k],
                          'certificationAlumni'
                      );*!/
                }
            }
        });*/
        this.route.queryParams.subscribe((params) => {
            this.category = '';
            this.alumniProfiles = of(null);
            this.isAlumniProfiles = false;
            this.isCertificateAlumni = false;
            this.tot = null;
            const programs = this.alumniService.getProgramAlumni(
                params?.ct.toLowerCase(),
                params?.type.toLowerCase()
            );
            programs.subscribe((program) => {
                console.log(program);
                if (program) {
                    this.programYear = program.year;
                    if (!program.groupPicture) {
                        this.groupPicture =
                            DOMAIN +
                            this.picturePath +
                            '/' +
                            program?.year +
                            ' ' +
                            program?.type.toUpperCase() +
                            ' ' +
                            'Group Picture.jpg';
                    } else if (!program.groupPicture.includes('://')) {
                        this.groupPicture =
                            DOMAIN +
                            this.picturePath +
                            '/' +
                            program.groupPicture;
                    } else {
                        this.groupPicture = program.groupPicture;
                    }

                    this.picturePath =
                        program.category.replaceAll('_', ' ').toUpperCase() +
                        '/' +
                        program?.year +
                        ' ' +
                        program?.type.toUpperCase();

                    program.alumni.map((al, index) => {
                        al.Picture = al?.Picture || al?.picture;
                        al.picturePath =
                            al?.Picture && al?.Picture.includes('://')
                                ? al.Picture
                                : DOMAIN + this.picturePath + '/' + al.Picture;
                        al.Country = al[' Country']
                            ? al[' Country']
                            : al.Country;
                        al.bgImage = this.sanitizer.bypassSecurityTrustStyle(
                            "url('" + al.picturePath + "')"
                        );

                        this.profilePicturesGallery.push({
                            id: index + 1,
                            src: al.picturePath,
                            caption: `${al.Name}, ${al.Country.toUpperCase()}`,
                            thumb: al.picturePath,
                        });
                        console.log();
                        return al;
                    });

                    if (program?.type === 'PIAM') {
                        this.fallBackImage = 'assets/img/harvard.jpg';
                    }

                    this.programYearAndTitle =
                        (program?.year || '') +
                        ' ' +
                        program?.type.toUpperCase();
                    this.programDescription = program?.description;

                    this.category = program.category
                        .replaceAll('_', ' ')
                        .toUpperCase();

                    if (program.hasToT) {
                        this.alumniService
                            .getToT(program.year, program.type)
                            .subscribe(
                                (tot) => {
                                    tot.map((al) => {
                                        al.Picture = al?.Picture ?? al?.picture;
                                        if (!al.Picture.includes('://')) {
                                            al.picturePath =
                                                DOMAIN +
                                                this.picturePath +
                                                '/' +
                                                al.Picture;
                                        } else {
                                            al.picturePath = al.Picture;
                                        }

                                        if (al.hasOwnProperty('visible')) {
                                            console.log(al);
                                        } else {
                                            al.visible = true;
                                        }
                                        return al;
                                    });
                                    this.tot = tot;
                                },
                                (error) => {
                                    console.log(error);
                                }
                            );
                    }
                    if (params.type && params?.type === 'certification') {
                        const certAlumni = program.alumni.map((al) => {
                            al.picturePath =
                                al?.Picture && al?.Picture.includes('://')
                                    ? al.Picture
                                    : `${DOMAIN}CERTIFICATIONS/${program?.type.toUpperCase()}/${
                                          al.Picture
                                      }`;
                            return al;
                        });

                        const groupedAlumni = this.groupBy(
                            certAlumni,
                            'AumniYear'
                        );
                        let sortByKeys = Object.entries(groupedAlumni).sort(
                            (a: any, b: any) => b - a
                        );

                        sortByKeys = sortByKeys.map((s: any) => {
                            s.year = s[0];
                            s.alumni = s[1];
                            delete s[0];
                            delete s[1];
                            return s;
                        });
                        this.alumniProfiles = of(sortByKeys.reverse());
                        this.isCertificateAlumni = true;
                        this.programYearAndTitle = program.title;
                        this.category = 'Certifications';
                        if (params?.type.toLowerCase() === 'certification') {
                            this.category = null;
                        }
                    } else {
                        this.isAlumniProfiles = true;
                        this.alumniProfiles = of(
                            program?.alumni.sort(
                                (x, y) => x.Country - y.Country
                            )
                        );
                    }
                    console.log(this.groupPicture);
                }
            });
        });
    }
}
